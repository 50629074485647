@import '../styles/_colors.scss';
@import '../styles/_fonts.scss';
@import '../styles/_mixins.scss';

* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
  @include custom-scrollbar;
}

body {
  position: fixed;
  overscroll-behavior-y: none;
}

main {
  @include font-default;
}

a {
  text-decoration: underline;
  cursor: pointer;
  color: $color--primary;
}

hr {
  color: $color--primary;
}

button {
  @include display--center;
  @include font-default;
  width: auto;
  height: auto;
  padding: 0 1rem 0 1rem;
  color: $color--tertiary;
  border: 2px solid $color--primary;
  border-radius: 10px;
  background-color: $color--primary;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: $color--secondary;
  }

  &:active {
    background-color: $color--tertiary;
  }

  &:focus {
    outline: none;
  }
}

.button--cancel {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border-radius: 50%;
}

input,
textarea {
  font-family: $font-family--default;
  background-color: $color--secondary;
  border: 2px solid $color--primary;
  border-radius: 6px;
  padding: 4px;

  &:focus {
    outline: none;
    background-color: $color--primary;
    border: 2px solid $color--tertiary;
  }
}

textarea {
  resize: none;
}

.avatar {
  outline: 4px solid $color--secondary;
  outline-offset: -2px;
  border-radius: 50%;
  max-width: 140px;
  max-height: 140px;
  margin-right: 2rem;
}

.image {
  &--loading {
    filter: blur(10px);
    clip-path: inset(0);
  }

  &--loaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
  }
}