$color--primary: #FFFFFF;
$color--secondary: #BBBBBB;
$color--tertiary: #575757;
$color--error: #ff4122;

$color--screen-background--dark: #8D8D93;
$color--screen-background: #C4C9CC;
$color--screen-border: #5D5E60;
$color--screen-background--active: #010081;
$color--screen-text: #1f2523;

$color--panel__header-background: rgba($color--primary, 0.25);
$color--panel__text-shadow: rgba($color--primary, 0.4);

$color--panel__background-stroke--one: rgba($color--primary, 0.035);
$color--panel__background-stroke--two: rgba($color--primary, 0.01);
$color--panel__background-gradient--start: rgba($color--screen-text, 0.8);
$color--panel__background-gradient--finish: rgba($color--primary, 0.5);

$color--gameboy--darkest: #0F380F;
$color--gameboy--dark: #306230;
$color--gameboy--light: #8BAC0F;
$color--gameboy--lightest: #9BBC0F;
$color--battery-light: #BB0000;

